export default {
  init() {
    if ('URLSearchParams' in window) {
      let searchParams = new URLSearchParams(window.location.search);
      let pushState = false;

      $('#house-nav [data-toggle="tab"]').on('shown.bs.tab', function (event) {
        const target = event.target.getAttribute('data-target').replace('#', '');
        const url = new URL(window.location)
        url.searchParams.set('tab', target)

        if (pushState) {
          history.pushState(null, '', url);
        }
      })

      window.addEventListener("popstate", () => {
        pushState = false;
        searchParams = new URLSearchParams(window.location.search);
        const $targetTab = $('#house-nav [data-target="#' + searchParams.get('tab') + '"]');

        if ($targetTab.length) {
          $targetTab.click();
        } else {
          $('#house-nav > li:first-child > [data-toggle="tab"]').click();
        }

        setTimeout(function() {
          pushState = true;
        }, 1000);
      });

      if (searchParams.get('tab')) {
        const $targetTab = $('#house-nav [data-target="#' + searchParams.get('tab') + '"]');

        if ($targetTab.length) {
          $targetTab.click();
        }
      }

      setTimeout(function() {
        pushState = true;
      }, 1000);
    }
  },
  finalize() {
    // JavaScript to be fired on the the content article archive page, after the init JS
  },
};
