import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js'
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min.js'
import Cookies from 'js-cookie'

export default {
  init() {
    // JavaScript to be fired on all pages

    // Smooth scrolling
    var scroll = new SmoothScroll('a[href*="#"]:not([href="#"])', {
      offset: function() {
        return $('#banner-bottom').outerHeight();
      },
    });

    // Bootstrap Lightbox
    $('[data-toggle="lightbox"]').click(function(e) {
      e.preventDefault();
      $(this).ekkoLightbox();
    });

    // GiveCloud cookies
    if (Cookies.get('account_name') && Cookies.get('account_name') != '') {
      $('#account_name').text(Cookies.get('account_name'));
      $('.authenticated-nav-item').removeClass('d-none');
      $('.unauthenticated-nav-item').addClass('d-none');
    }

    if (Cookies.get('cart_count') && Cookies.get('cart_count') != '0') {
      $('#cart_count').text(Cookies.get('cart_count')).removeClass('d-none');
    }

    // Modal cookies
    if ($('#modal').length > 0 && !Cookies.get('modalSuppressed')) {
      $('#modal').modal('show');

      $('#modal').on('hide.bs.modal', function () {
        Cookies.set('modalSuppressed', 1);
      });
    }

    // Fluidbox
    $('[data-fluidbox]').fluidbox();

    var prevArrow = '<button type="button" class="slick-next"><svg viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z" class="arrow"></path></svg></button>',
        nextArrow = '<button type="button" class="slick-prev"><svg viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z" class="arrow"></path></svg></button>';

    // Slick carousels
    $('[data-slick]').not('.slick-initialized').slick({
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      swipeToSlide: true,
      lazyLoad: 'progressive',
    });

    $('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
      $(e.target).closest('li').removeClass('active');
    });

    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
      $(e.target).closest('li').addClass('active');
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var $slick = $(e.target.getAttribute('data-target')).find('[data-slick]');
      var $matchHeights = $(e.target.getAttribute('data-target')).find('.match-height');

      if ($slick) {
        $slick.slick('slickGoTo', 0, true);
      }

      if ($matchHeights) {
        $matchHeights.matchHeight({});
      }
    });

    // Sticky header
    var $stickyHeader = $('#banner-bottom');
    Stickyfill.add($stickyHeader);

    // Footnote popups
    $('.content sup').each(function() {
      var $sup = $(this),
          references = $sup.text().split(','),
          popoverContent = '',
          firstParsedInt;

      $.each(references, function() {
        var parsedInt = parseInt(this);

        if (!isNaN(parsedInt)) {
          var $reference = $('#footnotes ol li:nth-child(' + parsedInt + ')');

          firstParsedInt = typeof(firstParsedInt) !== 'undefined' ? firstParsedInt : parsedInt;              

          if ($reference.length > 0) {
            var referenceContent = $reference.html();
            var elId = 'inline-footnote-' + firstParsedInt;

            if (popoverContent != '') {
              popoverContent += ', ';
            }
            popoverContent += referenceContent;

            $sup.attr('id', elId);
            $reference.click(function() {
              scroll.animateScroll(document.querySelector('#' + elId));
            });
            $reference.find('a').click(function(e) {
              e.stopPropagation();
            });
            $reference.css('cursor', 'pointer');
          }
        }
      });

      if (popoverContent != '' && firstParsedInt) {
        $sup.addClass('footnote-linked');
        $sup.tooltip({
          html: true,
          placement: 'bottom',
          title: popoverContent,
        });
        $sup.click(function(e) {
          e.preventDefault();

          scroll.animateScroll(document.querySelector('#footnotes ol li:nth-child(' + firstParsedInt + ')'));
        });
      }
    });

    // Inline videos
    $('.js-inline-video').click(function(e) {
      e.preventDefault();

      var src = $(this).data('src'),
          $iframe = $('<iframe src="' + src.replace("autoplay=false", "autoplay=true") + '&autoplay=true">');

      if (src) {
        $(this).append($iframe);
      }
    });

    // Toggle mobile nav active
    $('.nav-toggle').click(function(e) {
      e.preventDefault();

      $('body').removeClass('search-active');
      $('body').toggleClass('mobile-nav-active');
    });

    // Toggle search active
    $('.search-toggle').click(function(e) {
      e.preventDefault();

      $('body').removeClass('mobile-nav-active');
      $('body').toggleClass('search-active');
    });

    // Prevent following link on subnav init for mobile
    $('.page-subnav-menu-mobile a').click(function(e) {
      if ($(window).width() < 768) {
        e.preventDefault();
      }
    });

    // Launch fluidbox
    $('a[data-lightbox]')
      .on('thumbloaddone.fluidbox', function() {
        var $this = $(this).find('.fluidbox__wrap'),
            title = $this.find('img').attr('title');

        if (title) {
          $this.attr('title', title);
          $this.attr('data-placement', 'bottom');
          $this.attr('data-html', 'true');
          $this.tooltip({trigger: 'manual'})
            .on('mouseenter', function () {
              var _this = this;
              $(this).tooltip('show');
              $('.tooltip').on('mouseleave', function () {
                $(_this).tooltip('hide');
              });
            }).on('mouseleave', function () {
              var _this = this;
              setTimeout(function () {
                if (!$('.tooltip:hover').length) {
                  $(_this).tooltip('hide');
                }
              }, 300);
            });
        }
      })
      .on('openstart.fluidbox', function() {
        var $this = $(this).find('.fluidbox__wrap');
            
        $('body').addClass('fluidbox-open');
        $this.tooltip('hide').tooltip('disable');
      })
      .on('closestart.fluidbox', function() {
        $('body').removeClass('fluidbox-open');
      })
      .on('closeend.fluidbox', function() {
        var $this = $(this).find('.fluidbox__wrap'),
            title = $this.find('img').attr('title');

        if (title) {
          $this.tooltip('enable');
        }
      })
    .fluidbox();

    // Optin form Ajax submit
    $('#optin-form').submit(function(e) {
      e.preventDefault();

      var $form = $(this),
          recaptchaId = 'optin-form-recaptcha';

      $form.find('[type="submit"]').prop('disabled', true);

      grecaptcha.render(// eslint-disable-line no-undef
        recaptchaId, {
        'sitekey' : $('#' + recaptchaId).data('sitekey'),
        'callback' : optinFormSubmit,// eslint-disable-line no-undef
        'size' : 'invisible',
      });
      grecaptcha.execute();// eslint-disable-line no-undef
    });

    // Contact form Ajax submit
    $('#contact-form').submit(function(e) {
      e.preventDefault();

      var $form = $(this),
          recaptchaId = 'contact-form-recaptcha';

      $form.find('[type="submit"]').prop('disabled', true);

      grecaptcha.render(// eslint-disable-line no-undef
        recaptchaId, {
        'sitekey' : $('#' + recaptchaId).data('sitekey'),
        'callback' : contactFormSubmit,// eslint-disable-line no-undef
        'size' : 'invisible',
      });
      grecaptcha.execute();// eslint-disable-line no-undef
    });

    // Columnizer
    $('.two-columns').columnize({ columns: 2 });

    // Match height of carousel slides
    $('.carousel .slide .callout').matchHeight({});
    $('.match-height').matchHeight({});

    // Reset iframes on carousel slide change to stop playing videos
    $('.carousel').on('afterChange', function() {
      $(this).find('iframe').each(function(i,v) {
        var $iframe = $(v),
            src = $iframe.attr('src');

        if (!$iframe.closest('.slide').hasClass('is-selected')) {
          $iframe.attr('src', src);
        }
      });
    });

    // Open share links in new window
    $('.share-link').click(function(e) {
      e.preventDefault();
      window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    });

    // Wait to show image carousels until all images loaded
    $('.image-carousel').imagesLoaded( function() {
      $('.image-carousel').css('opacity', 1);
    });

    // Use tooltip on search field when focused
    $('.search-input').tooltip('disable');
    $('.search-input').focusin(function() {
      var $input = $(this);
      $input.tooltip('enable');
      $input.tooltip('show');
    });
    $('.search-input').focusout(function() {
      var $input = $(this);
      $input.tooltip('hide');
      $input.tooltip('disable');
    });

    // Related articles carousel
    var $window = $(window),
        $relatedArticleRows = $('.related-items-row'),
        carouselBreakpoint = 768,
        carouselInitialized = false,
        relatedArticlesCarouselOptions = {
          prevArrow: prevArrow,
          nextArrow: nextArrow,
          swipeToSlide: true,
        };

    var initRelatedArticlesCarousel = function() {
      if ($window.width() < carouselBreakpoint) {
        if ($relatedArticleRows.children().length > 2) {
          $relatedArticleRows.not('.slick-initialized').slick(relatedArticlesCarouselOptions);
          carouselInitialized = true;
        }
      } else {
        if (carouselInitialized) {
          $relatedArticleRows.slick('unslick');
          carouselInitialized = false;
        }
      }
    };
    initRelatedArticlesCarousel();
    $window.resize(initRelatedArticlesCarousel);

    $('.load-more-articles').click(function(e) {
      e.preventDefault();

      var $itemsToShow = $(this).closest('.related-items-section').find('.related-item-column.d-md-none:lt(3)');
      if ($itemsToShow.length > 0) {
        $itemsToShow.removeClass('d-md-none');

        var $itemsLeftToShow = $(this).closest('.related-items-section').find('.related-item-column.d-md-none:lt(3)');
        if ($itemsLeftToShow.length == 0) {
          $(this).addClass('d-none');
        }
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
