export default {
  init() {
    // JavaScript to be fired on the the content article archive page
    
    var inputName = 'content_filter';

    var disableButton = function($button) {
      $button.prop('disabled', true);
      $button.addClass('btn-secondary');
      $button.removeClass('btn-primary');
    };

    var enableButton = function($button) {
      $button.prop('disabled', false);
      $button.addClass('btn-primary');
      $button.removeClass('btn-secondary');
    };

    var hideTooltip = function($tooltip) {
      $tooltip.tooltip('hide');
      $tooltip.tooltip('disable');
    };

    var showTooltip = function($tooltip) {
      $tooltip.tooltip('enable');
      $tooltip.tooltip('show');
    };

    hideTooltip($('.content-filter-wrapper'));
    $('.content-filter-toplevel').change(function() {
      var termID = $(this).val();

      $('.content-filter').each(function() {
        var $select = $(this);

        if ($select.children().length > 1) {
          $select.val('');
        }
      });
      if (termID) {
        var $activeFilter = $('#content-filter-' + termID);
        $activeFilter.removeClass('d-none').attr('name', inputName);

        if ($activeFilter.children().length > 1) {
          disableButton($('.content-filter-submit'));
          showTooltip($('.content-filter-wrapper'));
        } else {
          enableButton($('.content-filter-submit'));
          hideTooltip($('.content-filter-wrapper'));
        }

        $('.content-filter:not(#content-filter-' + termID + ')').addClass('d-none').attr('name', '');
      } else {
        hideTooltip($('.content-filter-wrapper'));
        $('#content-filter-null').removeClass('d-none');
        $('.content-filter:not(#content-filter-null)').addClass('d-none').attr('name', '');
      }
    });

    $('.content-filter').change(function() {
      var termID = $(this).val();

      if (termID) {
        hideTooltip($('.content-filter-wrapper'));
        enableButton($('.content-filter-submit'));
      } else {
        showTooltip($('.content-filter-wrapper'));
        disableButton($('.content-filter-submit'));
      }
    });

    // Ajax loading of additional research archive content
    var resultsSelector = '.ajax-search-results',
        nextPageLinkSelector = '.nav-previous a',
        $nextPageLink = $('.ajax-search-nav').find(nextPageLinkSelector);

    $nextPageLink.click(function(e) {
      e.preventDefault();

      var $link = $(this),
          url = $link.attr('href');

      $link.addClass('disabled');
      $link.prop('disabled', true);

      $.ajax({
        type: 'GET',
        url: url,
        success: function (r) {
          var $results = $(r).find(resultsSelector),
              $newNextPageLink = $(r).find(nextPageLinkSelector);

          if ($results.length > 0) {
            $results = $results.children();
            $(resultsSelector).append($results);
            
            if ($newNextPageLink.length > 0) {
              $link.attr('href', $newNextPageLink.attr('href'));
              $link.removeClass('disabled');
              $link.prop('disabled', false);
            } else {
              $link.detach();
            }
          } else {
            // "Click the link" if this fails
            window.location.href = url;
          }
        },
        error: function () {
          // "Click the link" if this fails
          window.location.href = url;
        },
      });
    });
    
  },
  finalize() {
    // JavaScript to be fired on the the content article archive page, after the init JS
  },
};
