import Amplitude from 'amplitudejs';

export default {
  init() {
    if ($('#audio').length > 0 && $('#audio').attr('content') != '') {
      Amplitude.init({
        'bindings': {
          37: 'prev',
          39: 'next',
          32: 'play_pause',
        },
        'songs': [
          {
            'name': $('#audio-title').attr('content'),
            'artist': $('#audio-name').attr('content'),
            'url': $('#audio').attr('content'),
          },
        ],
      });
    
      window.onkeydown = function(e) {
        return !(e.keyCode == 32);
      };
    
      /*
        Handles a click on the song played progress bar.
      */
      document.getElementById('song-played-progress').addEventListener('click', function( e ){
        var offset = this.getBoundingClientRect();
        var x = e.pageX - offset.left;
    
        Amplitude.setSongPlayedPercentage( ( parseFloat( x ) / parseFloat( this.offsetWidth) ) * 100 );
      });

      $('#audio-player-open').click(function(e) {
        e.preventDefault();
  
        $('body').addClass('audio-player-active');
      });
  
      $('#audio-close').click(function(e) {
        e.preventDefault();
  
        $('body').removeClass('audio-player-active');
      });
    }
  },
  finalize() {
    // JavaScript to be fired on the the content article archive page, after the init JS
  },
};
