// import external dependencies
import 'jquery';
import 'jquery-columnizer/src/jquery.columnizer.js'
import 'slick-carousel/slick/slick.js'
import 'jquery-match-height/jquery.matchHeight.js'
import 'rangeslider.js/dist/rangeslider.js'
import 'ekko-lightbox/dist/ekko-lightbox.js'
import 'fluidbox/dist/js/jquery.fluidbox.min.js'
import 'parsleyjs/dist/parsley.js'
import 'imagesloaded/imagesloaded.pkgd.min.js'

// Import everything from autoload
import "./autoload/_bootstrap.js"

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import postTypeArchiveContentArticle from './routes/postTypeArchiveContentArticle';
import postTypeArchivePioneer from './routes/postTypeArchivePioneer';
import postTypeArchiveVideo from './routes/postTypeArchiveVideo';
import singleContentArticle from './routes/singleContentArticle';
import singleHouse from './routes/singleHouse';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // Content article archive page
  postTypeArchiveContentArticle,
  // Pioneer archive page
  postTypeArchivePioneer,
  // Video archive page
  postTypeArchiveVideo,
  // Content article single page
  singleContentArticle,
  // House single page
  singleHouse,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
